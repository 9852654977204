import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "mailto:hello@matteovivona.it"
      }}>{`Hi`}</a>{` (va benissimo in italiano) or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://twitter.com/tehkapa"
      }}>{`Twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://instagram.com/matteo.vivona"
      }}>{`Instagram`}</a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/tehKapa"
      }}>{`GitHub`}</a>{`, `}<a parentName="p" {...{
        "href": "https://keybase.io/kapa"
      }}>{`Keybase`}</a>{`, `}<a parentName="p" {...{
        "href": "https://live.xbox.com/it-IT/Profile?gamertag=kapa27"
      }}>{`Xbox Live`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.strava.com/athletes/kapa89"
      }}>{`Strava`}</a>{` & `}<a parentName="p" {...{
        "href": "https://linktr.ee/tehkapa"
      }}>{`linktree`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      