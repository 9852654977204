/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui";

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`;
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`);
  };

  return (
    <Box as="footer" variant="footer">
      {/* <button
        sx={{
          variant: `buttons.toggle`,
          fontWeight: `semibold`,
          display: `block`,
          mx: `auto`,
          mb: 3,
        }}
        onClick={toggleColorMode}
        type="button"
        aria-label="Toggle dark mode"
      >
        {isDark ? `Light` : `Dark`}
      </button> */}
      Copyright &copy; {new Date().getFullYear()}. All rights reserved.
      <br />
      <Flex
        sx={{
          justifyContent: `center`,
          alignItems: `center`,
          mt: 0.5,
          color: `text`,
          fontWeight: `thin`,
          a: { color: `text` },
        }}
      >
        {` `}
        <div sx={{ mx: 1 }}> Hosted by</div>
        <Link
          aria-label="Vercel is the optimal workflow for frontend teams."
          href="https://vercel.com"
        >
          Vercel.
        </Link>
        <div sx={{ mx: 1 }}> Made with</div>
        <Link
          aria-label="Blazing fast modern site generator for React."
          href="https://www.gatsbyjs.org/"
        >
          Gatsby.
        </Link>
        <div sx={{ mx: 1 }}> Forked by</div>
        {` `}
        <Link
          aria-label="Link to the theme's GitHub repository"
          href="https://github.com/LekoArts/gatsby-themes/tree/master/themes/gatsby-theme-cara"
        >
          Cara
        </Link>
      </Flex>
    </Box>
  );
};

export default Footer;
