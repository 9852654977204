import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Matteo Vivona`}</h1>
    <p>{`I'm an `}<a parentName="p" {...{
        "href": "https://linkedin.com/in/matteo-vivona-95197417"
      }}>{`freelance IT consultant`}</a>{` focused on cloud architectures and container orchestration via Kubernetes. `}<a parentName="p" {...{
        "href": "https://www.youracclaim.com/badges/1da2b382-7b03-40ee-847a-a17dd0d54e88/linked_in_profile"
      }}>{`Microsoft Certified`}</a>{`: Azure Administrator Associate since 2019.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      